import React, { Fragment } from 'react'

// Graphql
import { graphql } from 'gatsby'

// Layouts
import MainLayout from '@layouts/MainLayout'

// Components
import BorderedItem from '@components/BorderedItem'
import SEO from '@components/SEO'

// Utils
import { getReadingTime } from '@utils/getReadingTime'

const TagTemplate = ({ data, pageContext }) => {
  const { tag } = pageContext
  const { allMdx } = data
  const { edges, totalCount } = allMdx

  const tagNameCapitalize = tag.charAt(0).toUpperCase() + tag.slice(1)

  return (
    <Fragment>
      <SEO
        title={`${tagNameCapitalize} etiketi ile ilgili içerikler`}
        description={`${tagNameCapitalize} etiketi ile ilgili yazı, not ve içerikler`}
        keywords={`${tagNameCapitalize}, Nedir, Nasıl Kullanılır`}
        noIndexPage
      />
      <MainLayout>
        <h1 className='title'>
          {tag} ({totalCount})
        </h1>

        {edges.map((edge, index) => {
          const { fields, frontmatter } = edge.node

          const { title, tags, createdAt } = frontmatter
          const { slug, readingTime } = fields

          const formattedReadingTime = getReadingTime(readingTime.minutes)

          return (
            <BorderedItem
              key={index}
              title={title}
              tags={tags}
              slug={slug}
              createdAt={createdAt}
              readingTime={formattedReadingTime}
            />
          )
        })}
      </MainLayout>
    </Fragment>
  )
}

export default TagTemplate

export const tagQuery = graphql`
  query tagQuery($tag: String) {
    allMdx(filter: { frontmatter: { tags: { in: [$tag] } } }) {
      totalCount
      edges {
        node {
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            createdAt(formatString: "DD MMMM YYYY", locale: "tr")
            title
            tags
            metaDescription
            metaKeywords
          }
        }
      }
    }
  }
`
